<template>
<v-row>
<v-col>
    <v-data-table
        :headers="headers"
        :items="lista"
        :items-per-page="1000"
        single-expand
        :expanded.sync="expanded"
        dense
        item-key="name"
        :show-expand=false
        :loading = loadingTable
        :hide-default-footer = true
        class="elevation-1"
    >
         
        <template v-slot:top>
            <v-toolbar flat>
                <v-form ref="form_toolbar_search">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-menu>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            class="pt-5"
                                            v-model="dateRangeText"
                                            label="Intervalo de fechas"
                                            persistent-hint
                                            readonly
                                            append-icon="mdi-calendar-multiple"
                                            v-bind="attrs"
                                            v-on="on"
                                            clearable        
                                                                           
                                            click:clear="dates=''"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        range
                                        v-model="dates"
                                        
                                        no-title
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            
                           
                            
                    </v-row>
                    </v-col>
                </v-form>
                <v-btn small color="primary" @click="generarReporte">
                    <v-icon>mdi-magnify</v-icon> Buscar
                </v-btn>
                <v-btn small color="primary" @click="descargarReporte" class="ml-md-1">
                    <v-icon>mdi-file-excel</v-icon> Descargar
                </v-btn>
            </v-toolbar>
             
        </template>  
        
    	<template v-slot:footer>
    		<v-row>
    		<v-col class="col-md-10 text-right">
    			<strong> Total:</strong>
    		</v-col>
		    <v-col class="col-md-2 text-right pr-7">
		    	<strong></strong>
		    </v-col>
		    
		    
		    </v-row> 
		</template>   
    </v-data-table>
    
     
    
    </v-col>
    </v-row>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "ReporteServiciosAplicacionComponent",
  data: () => ({
    expanded: [],
    
    lista:[],
    options: {},
    dates: [],
    periodos: [],
    //accountingMonths:[],
    //accountingAccounts:[],
    headers: [
      { text: "Factura", value: "invoice_number" },
      { text: "Fecha", value: "invoice_date" },
      { text: "Nombres", value: "first_name" },      
      { text: "Total", value: "total", align:"right" },
      { text: "Saldo", value: "saldo", align:"right" }
      
    ],
    required:[
		v  => !!v || 'El cmapo es requerido'
	],
    requiredSearch:[
        v => !!v || 'El campo es requerido'
    ],
    hostname: window.location.protocol+'//'+window.location.hostname,
    partyInternalOrganization:'',
    
    singleExpand: false,
    
    periodo:'',
    
  }),
  computed: {
		...mapState('master',['loadingTable','url','months','urlExternal']),

		dateRangeText () {
            //console.log(this.dates)
			return this.dates.join(' ~ ')
        },
        
        	
        	 
    },
    methods:{
        ...mapMutations('master',['setUrl','setLoadingTable','setLoadingBtn','setTitleToolbar']),

		...mapActions('master',['errorRequest','requestApi','alertNotification']),

        generarReporte(){

            this.setUrl('reporte-servicio-aplicacion')
            this.setLoadingTable(true)
            this.requestApi({
				method: 'GET',
				data :{
					desde: this.dates[0],
					hasta: this.dates[1],
					periodo: this.periodo
				}
			}).then(res =>{
                this.lista  = res.data._embedded.reporte_servicio_aplicacion
                
                
                //this.dates = [res.data.lista.fecha_desde, res.data.lista.fecha_hasta]
                
                
                this.setLoadingTable(false)
                
			}).catch(()=>{

            }).then(()=>{
                this.setLoadingTable(false)
            })
        },

        
        

        descargarReporte(){
            this.setUrl('reporte-servicio-aplicacion')
            this.setLoadingTable(true)
            this.requestApi({
				method: 'POST',
				data :{
					desde: this.dates[0],
					hasta: this.dates[1],
					 
                    exportar: 'true'
                }
			}).then((res) => {
                console.log(res)
                this.setLoadingTable(false)
                let link = document.createElement("a");
                
                link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'+ res.data.datos;
            	link.download = res.data.archivo;
                link.click();
            }).catch((err) => {
                
            }).then(()=>{
                this.overlay = false;
                this.setLoadingTable(false)
            });

        }

    },
    
    mounted(){
        //this.generarReporte()
        
       // this.cargarPeriodos()
        this.setTitleToolbar('SERVICIOS DE TERCEROS')
    }
};
</script>